import React from 'react';
import PlaceVotes from './PlaceVotes'
import Socials from './Socials'

const RightPart = () => {
  return (
    <div className='w-2/5 sm:w-full'>
      <PlaceVotes />
      <Socials />
    </div>
  );
}

export default RightPart;
