const Socials = () => {
    return (
        <div>
            <div className="flex flex-row mb-3">
                <div className="h-[65px] w-[65px] cursor-pointer bg-blue rounded-half border-[2px] border-solid border-[#2e2e2e] flex items-center justify-center mr-3">
                    <a href="https://t.me/soldebt" rel="noreferrer" target="_blank" >
                        <img src="/img/telegram.png" alt="telegram" />
                    </a>
                </div>
                <div className="h-[65px] w-[65px] cursor-pointer bg-white rounded-half border-[2px] border-solid border-[#2e2e2e] flex items-center justify-center">
                    <a href="https://x.com/debtonsol_" rel="noreferrer" target="_blank" >
                        <img src="/img/twitter.png" alt="twitter" />
                    </a>
                </div>
            </div>
            <div className="flex flex-row items-end">
                <div className="h-[65px] w-[65px] cursor-pointer bg-grey rounded-half border-[2px] border-solid border-[#2e2e2e] flex items-center justify-center mr-3">
                    <img src="/img/pill.png" alt="pill" />
                </div>
                <span className="text-[#959595] text-[40px] sm:text-[25px]">COMING SOON...</span>
            </div>
        </div>
    )
}

export default Socials;