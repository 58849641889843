import React from 'react';
import LeftPart from './LeftPart'
import RightPart from './RightPart'

export function isMobileDevice() {
  return window.innerWidth <= 768; // You can adjust the width as needed
}
const HomePage = () => {
  return (
    <div className=' w-full bg-yellow p-[3%] flex sm:flex-col'>
        <LeftPart />
        <RightPart />
    </div>
  );
}

export default HomePage;