import React, { useMemo } from 'react';

const EnterSolanaWallet = ({ userInput, setUserInput }) => {

  const handleInput = (e) => {
    setUserInput(e.target.value)
  }
  const truncatedPubKey = userInput ? userInput.toString().substring(0, 6) + '...' + userInput.toString().substring(userInput.toString().length - 6) : ''
  const text = useMemo(() => {
    if (userInput) return truncatedPubKey
    else return null
  }, [truncatedPubKey, userInput])

  return (
    <div className="my-4 h-[80px] w-[85%] sm:w-full sm:h-10 border border-solid border-[#2e2e2e] rounded-half">
      <input
        type="text"
        placeholder="ENTER SOLANA WALLET"
        className="w-full h-full rounded-half p-4 font-semibold outline-none
        text-[40px] sm:text-[20px] text-green placeholder:text-green placeholder:text-[34px] placeholder:sm:text-[20px]"
        onChange={handleInput}
        value={text}
      />
    </div>
  )
};

export default EnterSolanaWallet;