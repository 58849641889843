import React from 'react';

const AmountInput = ({ userInput, setUserInput }) => {

  const handleInput = (e) => {
      if(!isNaN(+e.target.value)) {
        setUserInput(e.target.value)
      }
  }

  return(
    <div className="my-4 h-[80px] w-[85%] sm:w-full sm:h-10 border border-solid border-[#2e2e2e] rounded-half">
      <input 
        type="text" 
        placeholder="ENTER AMOUNT" 
        className="w-full h-full rounded-half p-4 font-semibold outline-none
        text-[40px] sm:text-[20px] text-green placeholder:text-green placeholder:text-[34px] placeholder:sm:text-[20px]"
        onChange={handleInput}
        value={userInput}
      />
    </div>
  )
};

export default AmountInput;