import React from 'react';

const DebtTracker = () => (
  <div className="bg-liteRed py-2 px-12 sm:px-2 rounded-lg shadow-md sm:w-[100%] w-[85%] h-[45vh] mb-4">
    <div className="text-red text-[50px] font-bold font-gorditas sm:text-[42px]">UNITED STATES <br /> OF TRENCHES <br /> $DEBT TRACKER</div>
    <p className="text-red text-[90px] font-bold leading-[1em] sm:text-[68px] sm:mt-4">
      548.47M<span className="text-red-500">▲</span>
    </p>
  </div>
);

export default DebtTracker;