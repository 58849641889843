import { ConnectionProvider, WalletProvider, } from '@solana/wallet-adapter-react';
import { clusterApiUrl } from '@solana/web3.js';
import { wallets } from './config/web3';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import HomePage from './pages/HomePage';
require('@solana/wallet-adapter-react-ui/styles.css');


function App() {
  return (
    <ConnectionProvider endpoint={clusterApiUrl('devnet')}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <HomePage />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>

  );
}

export default App;
