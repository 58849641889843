import React, { useState } from 'react';
import DebtTracker from './DebtTracker'; 
import AmountInput from './AmountInput'; 
import EnterSolanaWallet from './EnterSolanaInput';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import ActionButtons from './ActionButtons';
import WalletText from './WalletText';
require('@solana/wallet-adapter-react-ui/styles.css');

const LeftPart = () => {
  const [userInput, setUserInput] = useState();
  const [walletAddress, setWalletAddress] = useState();

  return (
    <div className='w-3/5 sm:w-full'>
      <DebtTracker />
      {/* <WalletMultiButton >
        <WalletText />
      </WalletMultiButton> */}
      
      <EnterSolanaWallet userInput={walletAddress} setUserInput={setWalletAddress} />
      <AmountInput userInput={userInput} setUserInput={setUserInput} />
      <ActionButtons userInput={userInput} />
    </div>
  );
}

export default LeftPart;
