import React from 'react';

const ActionButtons = ({ userInput }) => {

  const handleTwitterIntent = () => {
    const tweetText = encodeURIComponent(`@debtonsol_ will help me get rid of my ${userInput} $DEBT via https://mydebt.lol`);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
    window.open(twitterUrl, '_blank');
  };

  return(
    <div className="flex w-[85%] sm:w-full">
    <div className="flex bg-red text-black py-2 rounded h-[109px] sm:h-20 w-[48%] sm:text-[20px]
    items-center justify-center rounded-half border-[2px] border-solid border-[#2e2e2e] mr-6 sm:mr-2">
      <span className='text-large sm:text-[20px] text-[#2e2e2e]'>DEGEN</span>
      <img src="/img/skull.png" alt='degen' className='sm:h-10 sm:w-10'/>
    </div>
    <button 
    className="flex bg-green text-white py-2 rounded h-[109px] w-[48%] sm:h-20 text-[40px] sm:text-[20px]
    items-center justify-center rounded-half border-[2px] border-solid border-[#2e2e2e] cursor-pointer"
    onClick={handleTwitterIntent}>
      APPLY FOR $DEBT
    </button>
  </div>
  )
};

export default ActionButtons;