import { useState } from "react";

const PlaceVotes = () => {
    const [castedVote, setCastedVote] = useState(false)

    const castingVote = () => {
        setCastedVote(true)
    }

    return (
        <div className="w-full h-[550px] bg-white w-[70%] p-5 sm:w-full sm:mt-4 rounded-half border-[2px] border-solid border-[#2e2e2e] mb-5">
            <div className="mb-1 text-[30px] text-[#2e2e2e]">UNITED STATES <br /> OF TRENCHES <br /> LIVE POLLS</div>
            <div className="mb-1 text-[30px] text-[#2e2e2e]">PLACE YOUR VOTES <br /> FOR A BONUS DEBT</div>
            {castedVote ? <div className="flex">
                <div className="w-[49%] h-[80px] text-center p-2 bg-red rounded-l-[20px]  border-[2px] border-r-none border-solid border-[#2e2e2e] flex flex-row items-center justify-between text-white text-[32px] mb-5 cursor-pointer">
                    49%
                </div>
                <div className="w-[51%] h-[80px] p-2 bg-purple border-[2px] rounded-r-[20px] border-solid border-l-none border-[#2e2e2e] flex flex-row items-center text-white justify-between text-[30px] cursor-pointer">
                    51%
                </div></div> : <>
                <div onClick={castingVote}
                    className="flex space-between p-2 bg-red rounded-half border-[2px] border-solid border-[#2e2e2e] flex flex-row items-center justify-between text-white text-[32px] mb-5 cursor-pointer">
                    <span>DONALD DUCK</span>
                    <img src="/img/trump.png" className="h-[5vh]" alt="trump-icn" />
                </div>
                <div
                    onClick={castingVote}
                    className="flex space-between p-2 bg-purple rounded-half border-[2px] border-solid border-[#2e2e2e] flex flex-row items-center text-white justify-between text-[30px] cursor-pointer">
                    <span>CUMLOAD HEIST</span>
                    <img src="/img/kamala.png" alt="kamala-icn" />
                </div>
            </>}

        </div>
    )
}

export default PlaceVotes;